import Tooltip from '@/components/FlowUi/TooltipV2/TooltipV2';

import { ThemeContext } from '@/common/context/Theme';
import { themeTypes } from '@/common/context/Theme/ThemeContext';

import { memo, useContext, useMemo } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { SelectedContext } from 'slate-react/dist/hooks/use-selected';

type ChipContentProps = {
  selected: Partial<{ name: string; hierarchicalName?: string; url?: string }>;
  remove?: (selected: Partial<{ name: string }>) => void;
  label?: boolean;
  noWrap?: boolean;
  applySuggestion?:  (selected: Partial<{ name: string, hierarchicalName?: string; url?: string }>) => void;
};

export const ChipContent = memo(function ChipContent({
  selected,
  remove,
  label,
  applySuggestion,
  noWrap = true,
}: ChipContentProps) {
  const { textColor } = useContext<themeTypes>(ThemeContext);
  return (
    <>
      <style jsx>{`
        .chip {
          border: 1px solid ${textColor};
          font-family: 'Figtree';
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          border-radius: 15px;
          padding: 5px 12px;
          margin: 2px 0;
          color: ${textColor};
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 10px;
          cursor: grab;
          z-index: 6;
        }
      `}</style>

      <div className="chip">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 100,
            marginLeft: 7,
            whiteSpace: noWrap ? 'nowrap' : 'normal',
          }}
        >
          <>
            {' '}
            {applySuggestion? <span onClick={() => applySuggestion(selected)}>{selected?.name|| ''}</span> : <span>{selected?.name || ''}</span>}
            &nbsp; &nbsp;{' '}
            {!label && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                {remove && <IoIosCloseCircleOutline onClick={() => remove(selected)} />}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
});

const Chip = ({ selected, remove, label, noWrap, noToolTip, applySuggestion }: any) => {
  const tooltipContent = useMemo(() => {
    if (selected?.hierarchicalName || selected?.url || selected?.name) {
      return selected?.hierarchicalName || selected?.name;
    }
  }, [selected]);

  if (noToolTip) {
    return (
      <ChipContent
        noWrap={noWrap}
        selected={selected}
        remove={remove}
        label={label}
        applySuggestion={applySuggestion}
      />

    );
  }

  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent}>
        <ChipContent
          noWrap={noWrap}
          selected={selected}
          remove={remove}
          label={label}
          applySuggestion={applySuggestion}
        />
      </Tooltip>
    );
  } else {
    return (
      <ChipContent
        selected={selected}
        noWrap={noWrap}
        remove={remove}
        label={label}
        applySuggestion={applySuggestion}
      />
    );
  }
};

export default Chip;
